@mixin media-xs {
    @media (max-width: $screen-sm-min) {
        @content;
    }
}
@mixin media-sm {
    @media (min-width: $screen-sm-min) {
        @content;
    }
}
@mixin media-sm-only {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
        @content;
    }
}
@mixin media-md {
    @media (min-width: $screen-md-min) {
        @content;
    }
}
@mixin media-lg {
    @media (min-width: $screen-lg-min) {
        @content;
    }
}
@mixin media-retina {
    @media
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
        @content;
    }

}

