$content-spacing: 15px;
$color: #ff0000;
$brighterRed: lighten($color, 10);

$navbar-height: 60px;
$footer-height: 60px;
$home-slider-big-h: 800px;
$home-slider-md-h: 600px;

//unified colors
//primary 1, blue
$base-blue: #1d8cd2;
$primary1: $base-blue;
$shade-p1: shade($primary1, 40%);
$tint-p1: tint($primary1, 40%);
$light-p1: lighten($primary1, 10%);
$dark-p1: darken($primary1, 10%);
//primary 2, red
$base-red: #cc0000;
$primary2: $base-red;
$shade-p2: shade($primary2, 40%);
$tint-p2: tint($primary2, 40%);
$light-p2: lighten($primary2, 10%);
$dark-p2: darken($primary2, 10%);
//gray
$base-gray: rgb(64, 64, 64);
$secondary1: $base-gray;
$shade-s1: shade($secondary1, 40%);
$tint-s1: tint($secondary1, 40%);
$light-s1: lighten($secondary1, 10%);
$dark-s1: darken($secondary1, 10%);
//almost white
$base-aw: rgb(123, 123, 123);
$secondary2: $base-aw;
$shade-s2: shade($secondary2, 40%);
$tint-s2: tint($secondary2, 40%);
$light-s2: lighten($secondary2, 10%);
$dark-s2: darken($secondary2, 10%);
//text
$tp-light: white;//text primary light
$tp-dark: rgb(51, 51, 51);
$ts-light: rgb(218, 218, 218);//text secondary light
$ts-dark: rgb(136, 136, 136);

$screen-sm-min: 767px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
